.profilePicture {
    display: flex;
    background: var(--bfc-base-2);
    border-radius: 50%;
    image-rendering: -webkit-optimize-contrast;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

:global(.bf-nav-item) .profilePicture {
    border: 1px solid var(--bfc-base-c-dimmed);
}

.section {
    margin: var(--bfs16) 0;
}

.topbar .chat {
    border-left: 0;
}

.header {
    margin: 0 var(--bfs24);
    color: var(--bfc-base-c-2);
    font-size: var(--bf-font-size-s);
}

.oldPortal :global(.bf-nav-item-icon) {
    transform: scaleX(-1);
}

@media (max-width: 599.9px) {
    .chat.chat {
        background: transparent;
        border: 0;
        display: flex;
        justify-content: center;
    }

    .chatIcon {
        margin-right: var(--bfs4);
    }
}

@media (min-width: 1600px) {
    .sidebar :global(.bf-nav-side-content) {
        display: flex;
        flex-direction: column;
    }

    .oldPortal {
        margin-top: auto;
        border-top: var(--bfl-border);
    }
}
