.iconCheckBox :global(.bf-checkboxcard-icon) {
  display: none;
}

.wrapper {
  font-size: var(--bf-font-size-m);
  display: contents;
  background-color: var(--bfc-base-3);
  border: 1px solid var(--bfc-base-dimmed);
  border-radius: var(--bfl-border-radius);
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.label {
  background-color: var(--bfc-base-3);
  border: 1px solid var(--bfc-base-dimmed);
  border-radius: var(--bfl-border-radius);
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.header {
  background-color: var(--bfc-base-2);
}

.content {
  padding: var(--bfs12);
  font-weight: 400;
  color: var(--bfc-base-c-2);
  background-color: var(--bfc-base-3);
}

.labelSpan {
  display: flex;
  position: relative;
  color: var(--bfc-base-c-1);
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
}

.wrapper:hover .labelSpan {
  color: var(--bfc-theme);
}
